import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorPage from "./page/404.page";
import SuccessPage from "./page/success.page";
import FailedPage from "./page/failed.page";
import OtpPage from "./page/otp.page";
import AffiliateSignup from "./page/affiliate-signup.page";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<AffiliateSignup />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/failed" element={<FailedPage />} />

        <Route path="/otp" element={<OtpPage />} />
      </Routes>
    </Router>
  );
}

export default App;
