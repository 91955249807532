import React, { useState, useEffect } from "react";
import { Flex, Button, Input, Typography, Card, ConfigProvider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import imageUrl from "../assets/undraw_Security_on_re_e491.png";
import axiosInstance from "../helper/axios";

const { Title, Text } = Typography;

export default function OtpPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const { otpSuccessLink, contact_number, platform, token } =
    location.state || {};

  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [otpError, setOtpError] = useState(false);
  const [bearerToken, setBearerToken] = useState(token);

  useEffect(() => {
    if (!otpSuccessLink || !contact_number || !platform || !token) {
      navigate("/404");
    }
  }, [otpSuccessLink, navigate, contact_number, platform, token]);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const reSendOtp = async () => {
    try {
      setLoading(true);

      // send the otp
      // endpoint - /api/otp/send/${contact_number}/${platform}

      const response = await axiosInstance(bearerToken).post(
        `https://dory-service.dorydelivery.com/api/otp/send/${contact_number}/${platform}`
      );

      setBearerToken(response.data.token);

      setTimeout(() => {
        setLoading(false);
        setResendTimer(60);
      }, 1000);
    } catch (error) {
      console.error("Something went Wrong resending OTP");
      setLoading(false);
    }
  };

  const verifyOtp = async (otpCode) => {
    try {
      setOtpError(false);
      setLoading(true);
      // verify otp
      // endpoint - /api/otp/verify/${contact_number}/${platform}

      const response = await axiosInstance(bearerToken).get(
        `https://dory-service.dorydelivery.com/api/otp/verify/${contact_number}/${platform}/${otpCode}`
      );

      if (!response.data.is_verified) {
        setOtpError(true);
      } else {
        window.location.href = otpSuccessLink;
      }

      setLoading(false);
    } catch (error) {
      console.error("Something went Wrong verifying otp");
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#00BF63",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Card
          style={{
            width: 550,
            margin: 20,
          }}
        >
          <Flex gap="middle" align="center" vertical>
            {/* <Title level={1}>OTP</Title> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={imageUrl} alt="" width={120} />
            </div>
            <Title level={5}>
              Please enter the OTP sent to your mobile number {contact_number}.
            </Title>
            <Input.OTP
              length={6}
              size="large"
              variant="outlined"
              onChange={setOtpCode}
              style={{ gap: 15 }}
              status={otpError && "error"}
            />
            {otpError && (
              <Text strong style={{ color: "red" }}>
                Invalid OTP
              </Text>
            )}
            <Text type="secondary">Don't receive an OTP?</Text>

            {resendTimer > 0 ? (
              <Text type="secondary">Resend OTP in {resendTimer}s</Text>
            ) : (
              <Button type="link" onClick={reSendOtp} disabled={loading}>
                Resend OTP
              </Button>
            )}
            {/* <Button type="link" onClick={reSendOtp}>
              Resend OTP
            </Button> */}
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBg: "#00BF63",
                    defaultColor: "#FFF",
                  },
                },
              }}
            >
              <Button
                disabled={otpCode.length !== 6 || loading}
                block
                onClick={() => {
                  verifyOtp(otpCode);
                }}
              >
                Submit
              </Button>
            </ConfigProvider>
          </Flex>
        </Card>
      </div>
    </div>
  );
}
