import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Form,
  Divider,
  Row,
  Col,
  notification,
  Checkbox,
  ConfigProvider,
  Button,
} from "antd";
import icon from "../assets/dory-icon.png";
import phil from "../philippine-location-json-for-geer";
import renderInput from "../components/utilities/inputForms";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";

const { Title } = Typography;

const getUniqueOptions = (options, key) => {
  const seen = new Set();
  return options.filter((item) => {
    const keyValue = item[key];
    return seen.has(keyValue) ? false : seen.add(keyValue);
  });
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AffiliateSignup = () => {
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);
  const query = useQuery();
  const navigate = useNavigate();
  const referralCode = query.get("ref");

  const uniqueOptions = getUniqueOptions(phil.provinces, "prov_code");

  const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required field"),
    last_name: yup.string().required("Last Name is required field"),
    dob: yup
      .date()
      .required("Date of Birth is a required field")
      .max(new Date(), "Date of Birth cannot be in the future"),
    contact_number: yup
      .string()
      .required("Contact Number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    province: yup.string().required(),
    city: yup.string().required(),
    barangay: yup.string().required(),
    username: yup.string().required("username is required field"),
    password: yup.string().required("Password is required field"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      dob: "",
      contact_number: "",
      email: "",
      province: "",
      city: "",
      barangay: "",
      username: "",
      password: "",
    },
  });

  useEffect(() => {
    if (!referralCode) {
      navigate("/404");
    }
  }, [referralCode, navigate]);

  const onCheck = (e) => {
    setAcceptedTermsAndCondition(!acceptedTermsAndCondition);
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const province = phil.provinces.filter(
        (province) => province.prov_code === values.province
      )[0];
      const city = phil.city_mun.filter(
        (city) => city.mun_code === values.city
      )[0].name;
      const barangay = phil.barangays.filter(
        (barangay) => barangay.brgy_code === values.barangay
      )[0].name;

      const payload = {
        ...values,
        province: province.name,
        city,
        barangay,
        referral_code: referralCode,
      };

      const { data } = await axiosInstance().post("/api/affiliate", payload);

      if (data) {
        setLoading(false);

        navigate("/otp", {
          state: {
            otpSuccessLink: data.paymentLink,
            contact_number: values.contact_number,
            platform: "affiliate-web",
            token: data.token,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Something went wrong saving details");
      openNotification("ERROR", error.response.data.message);
    }
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onError = (errors) => {
    notification.error({
      message: "Form Submission Error",
      description: "Please check the form for errors and try again.",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        padding: "0 10px",
        boxSizing: "border-box",
        backgroundColor: "#00BF63",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: 700,
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={icon} alt="" width={120} />
        </div>

        <Title level={2} style={{ margin: 0, textAlign: "center" }}>
          Affiliate Application form
        </Title>
        <div style={{ marginTop: 20 }}>
          <Form
            layout="vertical"
            initialValues={{
              requiredMarkValue: true,
            }}
            requiredMark={true}
            onFinish={handleSubmit(onSubmit, onError)}
            encType="multipart/form-data"
          >
            <>
              <Divider orientation="left">Personal Information</Divider>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "First Name",
                      name: "first_name",
                      errors: errors,
                      required: "true",
                    },
                    control
                  )}
                </Col>

                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Last Name",
                      name: "last_name",
                      errors: errors,
                      required: "true",
                    },
                    control
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      type: "date",
                      label: "Date of birth",
                      name: "dob",
                      errors: errors,
                      required: "true",
                    },
                    control
                  )}
                </Col>
              </Row>
              <Divider orientation="left">Contact Information</Divider>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Contact Number",
                      name: "contact_number",
                      errors: errors,
                      required: "true",
                    },
                    control
                  )}
                </Col>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Email",
                      name: "email",
                      errors: errors,
                      required: "true",
                    },
                    control
                  )}
                </Col>
              </Row>
              <Divider orientation="left">Your Address Details</Divider>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Province",
                      name: "province",
                      errors: errors,
                      required: "true",
                      type: "select",
                      options: uniqueOptions,
                      valueKey: "prov_code",
                      valueText: "name",
                      onChangeOutside: onChangeProvince,
                    },
                    control
                  )}
                </Col>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "City/Municipality",
                      name: "city",
                      errors: errors,
                      required: "true",
                      type: "select",
                      options: cities,
                      valueKey: "mun_code",
                      valueText: "name",
                      onChangeOutside: onChangeCity,
                    },
                    control
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Barangay",
                      name: "barangay",
                      errors: errors,
                      required: "true",
                      type: "select",
                      options: barangays,
                      valueKey: "brgy_code",
                      valueText: "name",
                    },
                    control
                  )}
                </Col>
              </Row>
              <Divider orientation="left">Create username and password</Divider>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Username",
                      name: "username",
                      errors: errors,
                      required: "true",
                    },
                    control
                  )}
                </Col>

                <Col className="gutter-row" span={24} lg={12}>
                  {renderInput(
                    {
                      label: "Password",
                      name: "password",
                      errors: errors,
                      required: "true",
                      type: "password",
                    },
                    control
                  )}
                </Col>
              </Row>
              <Checkbox onChange={onCheck} style={{ marginBottom: 16 }}>
                {`I have read and agree to the `}
                <a
                  href="/AFFILIATE-MEMORANDUM OF AGREEMENT (MOA).pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms of service
                </a>
              </Checkbox>

              <div className="text-right mt-5" style={{ textAlign: "right" }}>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultBg: "#00BF63",
                        defaultColor: "#FFF",
                      },
                    },
                  }}
                >
                  <Button
                    block={true}
                    htmlType="submit"
                    type="default "
                    loading={loading}
                    disabled={!acceptedTermsAndCondition}
                  >
                    Continue
                  </Button>
                </ConfigProvider>
              </div>
            </>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AffiliateSignup;
